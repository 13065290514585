/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        function swapDots(slider, index) {
          $('.tmp-slider-navigation_dot[slider="' + slider + '"]').removeClass('active');
          $('.tmp-slider-navigation_dot[slider="'+ slider +'"][data-index="'+ index +'"]').addClass('active');
        }

        $('select').each(function() {
          $(this).selectpicker();
        });
        
        function calculateNavDrop() {
          var header = $('.site-header');
          var headerHeight = header.outerHeight(true);
          console.log(headerHeight);
        }

        // calculateNavDrop();

          /**
           * Load Gallery Lightboxes
           */

          // $('a.fl-gallery').featherlightGallery({
          //   previousIcon: '&#9664;',     /* Code that is used as previous icon */
          //   nextIcon: '&#9654;',         /* Code that is used as next icon */
          //   galleryFadeIn: 100,          /* fadeIn speed when slide is loaded */
          //   galleryFadeOut: 300          /* fadeOut speed before slide is loaded */
          // });

          // $('.pb--slider').owlCarousel({
          //   items: 1,
          //   nav: true
          // });
          // var ss = $('.services-slider');
          const ts = $('.testimonials-slider');

          ts.owlCarousel({
            // dotsContainer: '.tmp-slider-navigation--dots_wrapper[slider="ts"]',
            slideBy: 3,
            // move dotsContainer outside the primary owl wrapper
            dotsContainer: '.testimonials-slider-pagination--wrapper',
            responsive : {
              // breakpoint from 0 up
              0 : {
                items: 1,
                slideBy: 1,
                margin: 15,
                autoHeight: true
              },
              // breakpoint from 480 up
              561 : {
                  items : 2,
                  margin: 30,
                  slideBy: 1,
                  autoHeight: false
              },
              // breakpoint from 768 up
              992 : {
                  items: 3,
                  margin: 30,
                  slideBy: 3  
              }
          }
          });

          $('.testimonials-slider-arrow--wrapper').on('click', function() {
            if($(this).hasClass('tsa-right')) {
              ts.trigger('next.owl.carousel')
            } else {
              ts.trigger('prev.owl.carousel')
            }
          }) 

          ts.on('initialize.owl.carousel', function(event) {
            console.log("Initialize Event: " + event);
          })

          ts.on('changed.owl.carousel', function(event) {
            var index = event.item.index;
            swapDots('ts', index);
          });

          $('.tmp-slider-navigation_arrow').on('click', function() {
            var arrow = $(this);
            var slider = $(this).attr('tmp-slider');

            if( arrow.hasClass('left') ) {
              eval(slider).trigger('prev.owl.carousel');
            } else {
              eval(slider).trigger('next.owl.carousel');
            }
          });

          

         var html = $('html');

        $('.header--menu').on('click', function() {
            html.removeClass('loading').toggleClass('nav-open');
        });

        $('.nav-mobile-primary-close--wrapper').on('click', function() {
            html.removeClass('nav-open');
        });

        function swapTabs(tab) {
          const tabID = tab.attr('data-rb-tab');

          $('.rb-tab').removeClass('active');
          $('.rb-content').removeClass('active');
          $('.requirements-block-list--wrapper').removeClass('active');
          

          tab.addClass('active');
          $('.rb-content--' + tabID).addClass('active');
          $('.requirements-block-list-' + tabID + '--wrapper').addClass('active');
        }

        $('.rb-tab').on('click', function() {
          swapTabs($(this));
        });

        $('.nav-main-drop-icon').on('click', function() {
          $(this).parent('li').find('ul.nav-drop').first().toggleClass('active');
        });

        $('.nav-drop-link-icon').on('click', function() {
          $(this).parent().toggleClass('active');
        });


        // $('.nav-primary-ul .nav-main-item').on('mouseover', function() {
        //   $(this).addClass('active');

        //   $(this).on('mouseleave', function() {

        //     if($(this).hasClass('menu-item-has-children')) {
        //       var $this = $(this);

        //       setTimeout(function(){ 
        //         $this.removeClass('active');
        //       }, 1000);
        //     } else {
        //       $(this).removeClass('active');
        //     }
            
        //   });
        // });

        // $('.nav-primary-ul .nav-main-item').on('mouseleave', function() {
        //   console.log('mouseleaved.');
        //   setTimeout(function() {
        //     $(this).removeClass('active');
        //     console.log('removedClass.');
        //   }, 500);
        // });


        // $('.nav-main-drop-icon').on('click', function() {

        //   $(this).parentNode()

        // });

      },
      finalize: function() {
        AOS.init({
          disable: 'mobile'
        });
      }
    },
    'page-template-template-visa-form': {
      init: function() {
        
      }
    },
    'page_template_template_service_landing': {
      init: function() {
        $('.sl--slider').owlCarousel({
            slideBy: 1,
            dotsContainer: '.sl-slider-control--pagination',
            responsive : {
              // breakpoint from 0 up
              0 : {
                items: 1,
                slideBy: 1
              },
              // breakpoint from 480 up
              561 : {
                  items : 2,
                  margin: 30,
                  slideBy: 1
              },
              768: {
                items: 3,
                margin: 10
              },
              // breakpoint from 768 up
              992 : {
                  items: 4,
                  margin: 10,
                  slideBy: 1
              },
              1600 : {
                items: 5,
                margin: 10,
                slideBy: 1
              }
          }
        });

        $('.sl-slider-arrow--wrapper').on('click', function() {
          if($(this).hasClass('tsa-right')) {
            $('.sl--slider').trigger('next.owl.carousel')
          } else {
            $('.sl--slider').trigger('prev.owl.carousel')
          }
        }) 
        
      }
    },
    // Home page
    'home': {
      init: function() {
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_visa_form': {
      init: function() {
        $('select').selectpicker();
        $('ul#input_4_18 li').matchHeight();
      }
    },
    'page_template_template_contact': {
      init: function() {

        

          /*
          *  new_map
          *
          *  This function will render a Google Map onto the selected jQuery element
          *
          *  @type	function
          *  @date	8/11/2013
          *  @since	4.3.0
          *
          *  @param	$el (jQuery element)
          *  @return	n/a
          */
          
          function new_map( $el ) {
            
            // var
            var $markers = $el.find('.marker');
            
            
            // vars
            var args = {
              zoom		: 16,
              center		: new google.maps.LatLng(0, 0),
              mapTypeId	: google.maps.MapTypeId.ROADMAP
            };
            
            
            // create map	        	
            var map = new google.maps.Map( $el[0], args);
            
            
            // add a markers reference
            map.markers = [];
            
            
            // add markers
            $markers.each(function(){
              
                add_marker( $(this), map );
              
            });
            
            
            // center map
            center_map( map );
            
            
            // return
            return map;
            
          }
          
          /*
          *  add_marker
          *
          *  This function will add a marker to the selected Google Map
          *
          *  @type	function
          *  @date	8/11/2013
          *  @since	4.3.0
          *
          *  @param	$marker (jQuery element)
          *  @param	map (Google Map object)
          *  @return	n/a
          */
          
          function add_marker( $marker, map ) {
          
            // var
            var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
          
            // create marker
            var marker = new google.maps.Marker({
              position	: latlng,
              map			: map,
              animation: google.maps.Animation.DROP
            });
          
            // add to array
            map.markers.push( marker );
          
            // if marker contains HTML, add it to an infoWindow
            if( $marker.html() )
            {
              // create info window
              var infowindow = new google.maps.InfoWindow({
                content		: $marker.html()
              });
          
              // show info window when marker is clicked
              google.maps.event.addListener(marker, 'click', function() {
          
                infowindow.open( map, marker );
          
              });
            }
          
          }
          
          /*
          *  center_map
          *
          *  This function will center the map, showing all markers attached to this map
          *
          *  @type	function
          *  @date	8/11/2013
          *  @since	4.3.0
          *
          *  @param	map (Google Map object)
          *  @return	n/a
          */
          
          function center_map( map ) {
          
            // vars
            var bounds = new google.maps.LatLngBounds();
          
            // loop through all markers and create bounds
            $.each( map.markers, function( i, marker ){
          
              var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
          
              bounds.extend( latlng );
          
            });
          
            // only 1 marker?
            if( map.markers.length == 1 )
            {
              // set center of map
                map.setCenter( bounds.getCenter() );
                map.setZoom( 16 );
            }
            else
            {
              // fit to bounds
              map.fitBounds( bounds );
            }
          
          }
          
          /*
          *  document ready
          *
          *  This function will render each map when the document is ready (page has loaded)
          *
          *  @type	function
          *  @date	8/11/2013
          *  @since	5.0.0
          *
          *  @param	n/a
          *  @return	n/a
          */
          // global var
          var map = null;

          $('.acf-map').each(function() {

            // create map
            map = new_map( $(this) );
        
          });          

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
